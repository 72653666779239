
function DetailsView(props) {

	const { playing, curTime, details } = props;


	if (curTime <= (props.startingTime - .3)) {
		return (
			<span style={{ display: "inline-block", padding: "20px" }}>
				<div style={{ fontSize: "30px", marginTop: "30px" }}>{details.title}</div>
				<div style={{ fontSize: "20px", marginTop: "30px" }}>{details.description}</div>
				<div style={{ fontSize: "14px", marginTop: "70px" }}>তিলাওয়াতকারী: <span style={{ fontSize: "18px", marginLeft: "10px" }}>{details.reciter}</span></div>
				{details.translator && <div style={{ fontSize: "12px", marginTop: "8px" }}>Translator: {details.translator}</div>}
			</span>
		);
	} else {
		return (
			<></>
		)
	}
}

export default DetailsView;